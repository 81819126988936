.sidebar{
    flex-basis: 200px;
    width: 200px;
    height: 100vh;
    background-color: #2E3338;
    top: 40px;
    a{
        // color: white;
        font-size: 14px;
        font-weight: 400;
        &:hover{
            transition: all .15s;
            opacity: 0.7;
        }
    }
    &>li{
        .active{
            border-radius: 0;
        }
        .nav{
            padding-left: 10px;
        }
    }
}