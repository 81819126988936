.type-fields {
  & > h6 {
    margin-bottom: 16px;
  }
  .type-fields__cont {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    border-top: 1px solid #dee2e6;
    padding-top: 30px;

    .type-fields__elem {
      display: flex;
      align-items: center;
      padding: 10px 15px;
      border: 1px solid #ced4da;
      box-sizing: border-box;
      border-radius: 4px;
      cursor: pointer;

      .badge {
        margin-right: 10px !important;
        .bi {
          color: #fff;
        }
      }

      p {
        margin: 0px 15px;
        margin-left: 0px;

        font-size: 16px;
        line-height: 24px;

        flex-shrink: 0;
      }

      span {
        font-size: 16px;
        line-height: 24px;
        flex-shrink: 1;
        color: #bdbdbd;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
