.page-construct {
  &__list {
    height: 100vh;
    overflow: auto;

    .page-construct__collaps-btn {
      margin-top: 40px;
      font-family: "Helvetica Neue";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    ul {
      padding: 0px;
      padding-left: 0px;
      margin: 0px -10px;
      list-style: disc inside;

      li {
        padding: 10px 0px;
        padding-left: 18px;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 12px;

        position: relative;

        &:hover {
          background-color: #e9ecef;
        }
      }
    }
  }

  &__fields {
    height: 100vh;
    overflow: auto;
  }

  .element-list {
    padding: 0px;
    & > .elem-point {
      display: flex;
      justify-content: space-between;

      padding: 10px 15px;

      .left-block {
        display: flex;
        p {
          margin: 0px;

          span {
            color: #868e96;
          }
        }
      }
    }
  }

  .slash {
    border-bottom: 1px solid #dee2e6;
  }

  .check-discl {
    font-size: 16px;
    line-height: 22px;
    color: #868e96;
  }
}

.edit-field {
  p {
    margin-bottom: 10px;
  }

  .description {
    color: #868e96;
    font-size: 13px;
  }
  .form-check__radio {
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: #e9ecef;
    display: flex;
    overflow: hidden;
    padding: 0px;

    input {
      margin: auto 12px;
    }

    p {
      background-color: #fff;
      width: 100%;
      font-size: 16px;
      line-height: 24px;
      color: #000;
      padding: 31px 11px;
      margin: 0px;
      label {
        color: #868e96;
        font-size: 13px;
      }
    }
  }

  .select-modal {
    width: 100%;
  }
}
