.table {
  background-color: #fff;
  &-last-ch {
    .page-name {
      width: 220px;
      a {
        height: 100%;
        display: inline-flex;
        justify-content: center;

        span {
          display: inline-flex;
          max-width: 180px;
        }
      }
    }
  }
  &-defult {
    .page-name {
      width: 370px;
      a {
        span {
          width: 330px;
        }
      }
    }
  }
  &-pgs {
    .page-name {
      a {
        max-width: 80%;
        span {
          max-width: 100%;
        }
      }
    }
  }
  .t-checkbox {
    width: 15px;
  }
  .page-name {
    vertical-align: middle;
    a {
      color: #275382;
      transition: all 0.15s;
      &:hover {
        color: #007bff;
      }
      span {
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  tbody {
    tr:last-child {
      td {
        border-bottom-width: 0px;
      }
    }
    .control-icons {
      position: relative;
      i {
        margin-right: 20px;
        cursor: pointer;

        &:last-child {
          margin-left: 20px;
        }
      }
    }
  }
  thead {
    tr {
      background-color: #fff;
      th {
        border-bottom-color: #dee2e6 !important;
      }
    }
  }
  &-hover {
    tbody {
      tr {
        transition: all 0.15s;
        &:hover {
          background-color: #d3e8ff;
        }
      }
    }
  }
}

.stick-top-head {
  top: 60px;
}

.table-fast-edit > tbody > tr:nth-of-type(3n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}
