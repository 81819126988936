@import "../mixins/media";

.navbar{
   &_top{
        background-color: #2E3338 !important;
        padding: {
            top: 0px;
            bottom: 0px;
        };
        height: 40px;

        .search{
            border-radius: 0px;
            background-color: #282D32;
            border: 1px solid #474D54;
            border-top: 0px;
            border-bottom: 0px;
            color: white;
        }

        a, nav-link{
            color: white !important;
            font-size: 14px;
            font-weight: 400;
            &:hover{
                opacity: 0.7;
            }
        }
   }
}